

































































import Vue from 'vue'

import Pagination from '../components/BaseComponents/BasePagination/BasePagination.vue'
import VSelect from '../components/BaseComponents/VSelect/VSelect.vue'
import SelectedManageItem from '../components/interests/SelectedManageItem.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default Vue.extend({
  name: 'InterestsExploreSelectedManage',
  data () {
    return {
      activePage: 1,
      sortDirectionAudienceSize: 'desc',
      start: 0,
      end: 10,
      per_page: 10
    }
  },
  components: { Pagination, SelectedManageItem, VSelect },
  methods: {
    ...mapMutations('interests', ['deleteCollectionItem', 'clearAllItems', 'setStateField']),
    ...mapActions('interests', ['getCollectionData']),
    deleteItem (audienceId) {
      this.deleteCollectionItem(audienceId)
      this.setStateField({ field: 'appliedLayeringChanges', value: false })
    },
    sortByAudience () {
      const direction = this.sortDirectionAudienceSize === 'desc'
      this.sortDirectionAudienceSize = direction ? 'asc' : 'desc'
      const x = direction ? -1 : 1
      const y = direction ? 1 : -1
      this.interestsCollection.sort((a, b) => {
        if (Number(a.audience_size) > Number(b.audience_size)) return x
        if (Number(a.audience_size) < Number(b.audience_size)) return y
        return 0
      })
    },
    paginating (arr, a, b) {
      return arr.slice(a, b)
    },
    changePage (page) {
      this.activePage = page
      this.start = (page - 1) * this.per_page
      this.end = (page - 1) * this.per_page + this.per_page
    },
    changePerPage (acc) {
      this.per_page = acc
      this.changePage(this.activePage)
    }
  },
  computed: {
    ...mapState('interests', ['interestsCollection', 'selectedInterests'])
  }
})
