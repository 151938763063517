
















import Vue from 'vue'
export default Vue.extend({
  name: 'SelectedManageItem',
  // props: {
  //   interestName: {
  //     type: String
  //   },
  //   layer: {
  //     type: Number
  //   },
  //   type: {
  //     type: String
  //   },
  //   audienceSize: {
  //     type: String
  //   },
  //   audienceId: {
  //     type: String
  //   }
  // },
  props: ['interestName', 'layer', 'type', 'audienceSize', 'audienceId'],
  methods: {
    deleteItem () {
      this.$emit('delete-item')
    }
  }
})
